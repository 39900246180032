.input {
  &[type="text"],
  &[type="email"],
  &[type="password"],
  &[type="number"],
  &[type="phone"] {
    @include p14;

    height: 48px;
    width: 100%;
    padding: 0 0.8571em;

    border-radius: 12px;
    border: 1px solid $grey-e5;
    appearance: none;

    &::placeholder {
      font-family: $base-font;

      color: $grey-88; } } }

.required-input {
  position: relative;

  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%); } }

.required-input--z-56 {
  position: relative;

  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 56; } }
