.prizes {
  padding-top: 3.75em;
  position: relative;

  @include media-tablet {
    padding-top: 5em; }

  @include media-tablet-h {
    padding-top: 6.25em; }

  &__header {
    margin-bottom: 1.875em;

    @include media-tablet {
      margin-bottom: 3.75em; }

    @include media-tablet-h {
      margin-bottom: 5em; }

    &-cont {
      display: grid;
      row-gap: 1.875em;

      @include media-tablet {
        grid-template-columns: 15em 43.75em;
        justify-content: space-between;
        column-gap: 5em; }

      @include media-tablet-h {
        grid-template-columns: 1fr 43.75em; } } }

  &__title {
    @include reset;
    @include h36;
    position: relative;
    color: $color-black;
    z-index: 2;

    @include media-tablet {
      @include h48; } }

  &__desc {
    display: flex;
    flex-direction: column;
    gap: 14px;
    @include fs18;
    position: relative;
    z-index: 2;
    color: $color-black;

    & a {
      color: #185ADB;
      text-decoration: underline; } }

  &__body {
    padding-bottom: 56px;

    @include media-tablet {
      padding-bottom: 64px; }

    @include media-tablet-h {
      padding-bottom: 100px; }

    @include media-desktop-1440 {
      padding-bottom: 120px; }

    @include media-desktop-1920 {
      padding-bottom: 140px; }

    .cont {
      @include max-w(768px) {
        width: 100%; } } }

  .swiper-container.swiper-container-horizontal {
    padding-bottom: 2.125em;

    .swiper-pagination {
      bottom: 0; }

    .swiper-pagination-bullet {
      width: 0.625em;
      height: 0.625em;
      margin: 0 0.375em;
      background: $grey-80;
      opacity: 1; }

    .swiper-pagination-bullet-active {
      background: #C0C0C0; } }

  &__slider {
    &-wrapper {
      display: flex;
      position: relative;
      width: 100%;
      z-index: 1;

      .swiper-slide {
        height: 100% !important; } }

    &-nav {
      position: absolute;
      top: 14.9375em;
      transform: translateY(-100%);
      z-index: 50;
      display: none;
      width: 2.9375em;
      height: 3.5em;
      background-color: $grey-80;
      border-radius: 2em 0 0 2em;
      background-image: url(@public/images/prizes/prizes-slider-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      &:hover {
        background-color: $grey; }

      @include media-tablet {
        display: block; }

      &--prev {
        left: 0;
        transform: translateY(-100%) rotate(180deg); }

      &--next {
        right: 0; }

      &--disabled {
        display: none; } } }

  &__slide {
    display: grid;
    grid-template-rows: 11.75em 1fr;
    box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
    background-color: $white;
    border-radius: 24px;
    overflow: hidden;

    @include media-tablet {
      grid-template-rows: 14.9375em 1fr; }

    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 0.75em;
      min-height: 11.5625em;
      padding: 1.25em;

      @include media-tablet {
        min-height: 12.0625em;
        padding: 1.875em 2.5em; } }

    &-stand {
      @include fs16;
      color: $grey-89;
      font-weight: 700;
      margin-top: auto; }

    &-bg {
      border-radius: 0.75em;
      overflow: hidden;

      @include media-tablet {
        border-radius: 0; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }

    &-title {
      @include reset;
      @include fs18;
      font-weight: 800;

      @include media-tablet {
        @include fs24; }

      a {
        color: $blue;
        text-decoration: underline; } }

    &-link {
      color: $red;
      font-weight: 800;
      align-self: end; } } }
