.schedule {
  padding-top: 2em;
  color: $dark-grey;
  background-color: $grey-f0;
  padding-bottom: 2em;

  @include media-tablet {
    padding-top: 4.375em;
    padding-bottom: 3.5em; }

  @include media-desktop {
    padding-bottom: 4em; }

  &__wrapper-filter {
    display: flex;
    justify-content: space-between; }

  &__flex {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    @include media-desktop-1440 {
      flex-direction: row; }

    @include media-desktop {
      justify-content: space-between; } }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include media-tablet {
      gap: 40px; }

    @include media-desktop-1440 {
      gap: 60px; } }

  &__flow {
    padding-top: 0;
    @include media-tablet {
      padding-top: 2.5em; }

    &-title {
      display: grid;
      color: $grey-c0;
      border-radius: 0.5em;
      background-color: $grey-ee;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr); }

      &-item {
        padding: 1.25em;
        text-align: center;
        color: $grey-c0;
        font-weight: 800;

        @include media-tablet {
          @include fs18;
          padding: 1.1111em;
          text-align: left; }

        @include media-tablet-h {
          @include h24;
          padding: 0.8333em 1.6667em; }

        @include media-desktop {
          @include h36;
          padding: 0.6667em 1.1111em;
          text-align: left; }

        &:nth-child(2n) {
          @include media-tablet {
            text-align: right; } }

        &--active {
          color: $dark-grey;
          background-color: $white;
          box-shadow: 0.1111em 0.1111em 0.3333em rgba(0, 0, 0, 0.15);
          border-radius: 0.2222em;

          span {
            color: $red; } } } }

    &-header {
      // display: flex
      // временно скрыто переключение к табличному расписанию
      display: none;
      justify-content: space-between;
      align-items: flex-end;
 }      // margin-top: 2.5em

    &-desc {
      @include lreset;
      width: 31.111em;

      li {
        @include p16;
        position: relative;
        padding-left: 0.875em;

        &::before {
          content: "";
          position: absolute;
          top: 0.7em;
          left: 0.0625em;
          width: 0.25em;
          height: 0.25em;
          background-color: $dark-grey;
          border-radius: 50%; } } }

    &-view {
      @include fs14;
      position: relative;
      display: none;

      @include media-desktop {
        display: block; }

      &--open {
        .schedule__flow-view-switch {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; }

        .schedule__flow-view-dropdown {
          display: block; } }

      &-switch {
        padding: 0.8571em 1.4286em;
        padding-right: 2em;
        border-radius: 2.0625rem;
        border: 1px solid $dark-grey;
        cursor: pointer;
        position: relative;

        &:after {
          width: 0.7em;
          height: 0.7em;
          content: '';
          position: absolute;
          background: url(/public/images/schedule/schedule-arrow.svg) no-repeat center;
          background-size: contain;
          right: 1em;
          top: 1.1em; }

        &>span {
          color: $grey-80; } }

      &-dropdown {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 0.8571em 1.4286em;
        padding-right: 0;
        border: 1px solid $dark-grey;
        border-top: none;
        border-radius: 0 0 2.0625rem 2.0625rem;
        background-color: $grey-f5;

        &-item {
          padding: 0.5em 0;
          cursor: pointer;

          span {
            &:nth-child(1) {
              opacity: 0; }

            &:nth-child(2) {
              color: $grey-80; } } } } } }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.25em; }

  &__title {
    @include h36;
    font-weight: 800;
    @include media-tablet {
      @include h48; } }

  &__desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.25em;

    @include media-tablet-h {
      flex: 0 0 auto;
      width: calc(50% - 1em); }

    p {
      @include reset;
      @include fs16; } }

  &__filter {
    grid-column: 1 / span 2;
    grid-row: 2;
    margin-top: 3.5em;

    &-group {
      display: grid;
      margin-bottom: 2.5em;
      row-gap: 1em;

      @include media-tablet {
        grid-template-columns: 5em 1fr;
        column-gap: 2em;
        margin-bottom: 1em; }

      @include media-desktop {
        grid-template-columns: auto 1fr;
        column-gap: 1.562em; }

      &:last-of-type {
        margin-bottom: 0; } }

    &-title {
      @include fs18;
      display: flex;
      align-items: center;
      color: $grey-88; }

    &-list {
      @include lreset;
      display: flex;
      flex-wrap: wrap; }

    &-item {
      margin-right: 1em;

      &:last-of-type {
        margin-right: 0; }

      label {
        display: block;
        margin-bottom: 0.5em;
        padding: 0.312em 0.555em;
        background-color: $white;
        border-radius: 0.25em; }

      input:checked + label {
        color: $white;
        background-color: $red; } } }

  &__list {
    @include lreset;
    display: grid;
    row-gap: 0.75rem;

    &--hidden {
      display: grid;

      @include media-desktop {
        display: none; } }

    &-day {
      display: grid;
      border-radius: 1.5em;
      overflow: hidden;

      @include media-tablet;

      &-header {
        padding: 1.25em 1.5em;
        min-height: 3.5em;
        color: $color-black;
        background-color: $grey-e5;
        cursor: pointer;

        @include media-tablet {
          padding: 0 2em;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }

        &--archived {
          color: $color-black;
          background-color: $grey-c0;

          .schedule__list-day-date {
            .schedule__list-day-week {
              display: none;

              @include media-tablet-h {
                display: inline; } }

            .schedule__list-day-date-name {
              display: none;

              @include media-desktop-1440 {
                display: inline; } }

            hr:last-of-type {
              display: none;

              @include media-tablet-h {
                display: block; } } } }

        &--networking-day {
          background-color: $color-yellow-new; } }

      &-date-wrapper {
        display: flex;
        gap: 18px;
        @include fs18;

        @include media-tablet {
          @include fs20; }

        @include media-desktop {
          @include fs24; } }

      &-date {
        position: relative;
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.75rem;
        align-items: center;
        grid-auto-columns: max-content;

        hr {
          @include reset;
          height: 70%;
          width: 2px;
          border: none;
          background-color: $color-black; } }

      &-time {
        position: relative;
        padding-left: 1.25em;
        font-weight: 800;

        &::before {
          content: "";
          position: absolute;
          top: 0.2em;
          bottom: 0.2em;
          left: 0.62em;
          width: 0.083em;
          background-color: $dark-grey; } }

      &-timezone {
        position: absolute;
        top: 0;
        right: -0.25em;
        transform: translateX(100%);
        font-size: 0.583em; }

      &-body {
        display: grid;
        row-gap: 0.5em;
        max-height: 400em;
        background-color: $grey-f5;
        transition: all 0.3s;

        @include media-tablet {
          row-gap: 0.75em; }

        @include media-tablet-h {
          row-gap: 0.5em; }

        @include media-desktop {
          row-gap: 0.25em; } }

      &--hidden {
        .schedule__list-day {
          &-header {
            border-radius: 1.25em; }

          &-body,
          &-netw-body {
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
            max-height: 0; } } } } }

  &__item {
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 0; }

    &-flow {
      @include fs14;
      margin-top: 0.571em;
      font-weight: 800;

      @include media-tablet {
        @include fs18;
        margin-top: 0; } }

    &-body {
      display: flex;
      padding: 24px 20px;
      background-color: $white;
      flex-direction: column;
      gap: 40px;
      justify-content: space-between;

      @include media-tablet {
        padding: 40px 32px; }

      @include media-desktop {
        flex-direction: row; } }

    &-date {
      display: flex;
      gap: 4px;
      font-weight: 700;
      @include fs24;

      @include media-tablet {
        gap: 8px;
        font-size: 3rem; }

      @include media-tablet-h {
        @include h36; }

      &-timezone {
        font-size: 0.875rem;
        text-transform: uppercase;

        @include media-tablet {
          font-size: 1.125rem; }

        @include media-tablet-h {
          font-size: 0.4444em; } }

      &-block {
        margin-bottom: 12px;

        @include media-tablet {
          margin-bottom: 24px; }

        @include media-tablet-h {
          margin-bottom: 40px; } } }

    &-content {
      display: none;
      flex-direction: column;

      @include media-tablet-h {
        display: flex; } }

    &-company {
      margin-bottom: 24px;

      &-title {
        color: $grey-88; } }

    &-title {
      @include reset;
      @include fs14;

      @include media-tablet {
        @include fs18;
        font-weight: 700;
        color: $dark-grey; }

      @include media-desktop {
        @include fs16; } }

    &-desc {
      @include reset;
      @include p14;
      line-height: 18.2px;

      @include media-tablet {
        @include p16;
        line-height: 20.8px; } }

    &-longdesc {
      @include p14;
      padding: 0 0.8571em;
      height: 0;
      max-height: 0;
      color: $dark-grey;
      background-color: $grey-f5;
      overflow: hidden;
      transition: all 0.3s ease;

      p {
        margin: 0.75rem 0; }

      ol,ul {
        @include reset;
        margin: 0.5rem 0;
        padding-left: 1.25rem;

        li {
          margin: 0.25rem 0; } }

      &--active {
        padding: 24px;
        height: auto;
        max-height: 300em;
        border-radius: 0 4px 4px 4px; } }

    &-more-btn {
      @include reset;
      @include fs14;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      justify-self: start;
      background-color: $grey-f5;
      padding: 8px 20px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &--opened {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        svg {
          transition: rotate 0.3s ease;
          rotate: 180deg; } } }


    &-btn-block {
      grid-area: btn; }

    &-participants {
      @include fs14;
      margin-top: 16px;

      span {
        color: $dark-grey; } }

    &-slider-block {
      position: relative;
      grid-area: slider;
      margin-bottom: 0.75rem;

      @include media-tablet {
        margin-bottom: 1.125rem; } }

    &-slider-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      position: relative;

      @include media-desktop {
        gap: 24px; }

      .swiper-button-disabled {
        display: none; } }

    .swiper-slide {
      width: 13.875em; }

    &-slider {
      position: relative;

      &-nav {
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        width: 2.5em;
        height: 3.5em;
        background-color: $grey-e5;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-slider-arrow.svg");
          background-repeat: no-repeat;
          background-position: center; }

        &--disabled {
          visibility: hidden; } }

      &-prev {
        left: -2em;
        border-radius: 0.375em 0 0 0.375em;

        &::before {
          transform: rotate(180deg); } }

      &-next {
        right: -2em;
        border-radius: 0.375em 0 0 0.375em; } }

    &-slide {
      display: flex;
      color: $dark-grey;

      &-img {
        margin-right: 1em;
        width: 3.5em;
        height: 3.5em;
        flex-shrink: 0;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          overflow: hidden; } }

      &-content {}

      &-title {
        font-weight: 800; }

      &-desc {
        margin-top: 0.363em;
        font-size: 0.6875em;
        line-height: 1.35; } }

    &-netw {
      &-body {
        display: grid;
        row-gap: 1.5em;
        max-height: 400em;
        padding: 2.5rem 2rem;
        background-color: $white;
        transition: all 0.3s;

        @include media-tablet-h {
          grid-template-columns: 1fr 13.1875rem;
          column-gap: 5rem; }

        @include media-desktop {
          grid-template-columns: 1fr 16.5rem; } }

      &-content {
        display: grid;
        row-gap: 0.75rem;

        @include media-tablet {
          grid-template-columns: 18.75rem 1fr;
          column-gap: 1rem; }

        @include media-tablet-h {
          grid-template-columns: 1fr; } }

      &-title {
        @include reset;
        @include h36; }

      &-desc {
        @include reset;
        @include p16;

        @include media-tablet {
          grid-column: 2; }

        @include media-tablet-h {
          @include p18;
          grid-column: 1; } }

      &-btn {
        @include media-tablet {
          grid-column: span 2; }

        @include media-tablet-h {
          display: flex;
          align-items: center;
          grid-column: 2 / span 1; }

        .btn {
          width: 100%;
          min-width: auto;
          height: 2.8125rem;
          padding: 0 1em;
          font-size: 1.125em; } } }

    &--special {
      border: 2px solid $red; } }

  &__custom {
    &--disabled {
      .disabled {
        @include fs18;
        display: flex;
        min-width: auto;
        height: 2.8125rem;
        padding-left: 0.9rem;
        padding-right: 0.9rem;

        @include media-tablet-h {
          @include fs16; }

        @include media-desktop {
          @include fs18; } } }

    &-body {
      grid-template-areas: "custom" "company" "slider" "btn";

      @include media-tablet {
        grid-template-areas: "custom custom" "company company" "slider slider" "btn btn"; }

      @include media-tablet-h {
        grid-template-areas: "custom custom btn" "company company company" "slider slider slider"; } }

    &-list {
      display: grid;
      grid-area: custom;
      row-gap: 1.125em;

      @include media-tablet {
        row-gap: 1.875em; } }

    &-item {
      display: grid;
      grid-template-areas: "date" "content";
      row-gap: 0.75em;

      @include media-tablet {
        grid-template-areas: "date content";
        grid-template-columns: 6.8125em 1fr;
        column-gap: 1em; }

      @include media-tablet-h {
        grid-template-columns: 9.0625em 1fr; }

      @include media-desktop {
        grid-template-columns: 9.5625em 1fr; }

      .schedule__item-desc {
        @include media-tablet {
          @include p16; } }

      &:not(:last-child) {
        border-bottom: 1px solid $grey-e5;

        @include media-tablet {
          padding-bottom: 1.875em; } } } }

  &__table {
    display: none;

    @include media-tablet-h {
      display: block; }

    &--hidden {
      display: none; }

    &-filter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      @include media-tablet {
        align-items: center;
        gap: 16px;
        flex-direction: row; }

      &-title {
        @include fs18;
        color: $grey-88;
        text-align: right; }

      &-list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        @include media-tablet {
          gap: 20px; } }

      &-item {
        @include fs14;
        padding: 5px 9px;
        border-radius: 33px;
        background-color: $white;
        cursor: pointer;
        user-select: none;
        letter-spacing: -0.4px;

        @include media-tablet {
          letter-spacing: normal;
          padding: 5px 14px;
          @include fs18; }

        &--active {
          color: #000222;
          background-color: #FFEE00; } } }

    &-week {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 0.5em; }

    &-day {
      position: relative;
      display: grid;
      row-gap: 0.125em;
      border-radius: 0.5em;
      overflow: hidden; }

    &-header {
      @include fs14;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      background-color: $grey-88;
      padding-right: 6px;
      padding-left: 24px;

      &--networking-day {
        font-weight: 800;
        background-color: $color-red;
        color: $white; }

      &-logo {
        position: absolute;
        right: 0;
        top: 0;
        width: 5em;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center; } } }

    &-bg-wrapper {
      position: absolute;
      top: 2.625em;
      left: 0;
      right: 0;
      display: grid;
      row-gap: 0.125em; }

    &-bg {
      padding: 1.5em;
      grid-row: auto / span 1;
      background-color: $white;

      &-time {
        @include reset;
        position: relative;
        display: inline-block;
        color: $grey-ee;
        font-weight: 800;

        span {
          @include fs11;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(110%); } } }

    &-item-wrapper {
      position: absolute;
      top: 2.625em;
      left: 0;
      right: 0;
      display: grid;
      row-gap: 0.125em; }

    &-item {
      display: flex;
      flex-direction: column;
      padding: 1.5em;
      overflow: hidden;
      background-color: $white;

      &--special {
        border: 1.5px solid $red; }

      &-header {
        display: flex;
        justify-content: space-between;
        font-weight: 700; }

      &-date {
        position: relative;
        font-weight: 700;
        font-size: 1.5rem;

        span {
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(110%);
          font-size: 0.875rem; } }

      &-flag {
        @include fs11;
        width: 44.7%;
        text-align: right;
        font-weight: 800;
        color: $color-red; }

      &-desc {
        @include reset;
        margin-top: 12px;
        overflow: hidden;
        color: $dark-grey;
        white-space: pre-wrap;
        text-overflow: ellipsis; }

      &-bottom {
        margin-top: auto;

        .btn {
          margin-left: auto; } }

      &-btn {
        @include reset;
        @include fs11;
        width: 10.454em;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: $grey-88;
        text-align: right;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:focus {
          outline: none; }

        * {
          pointer-events: none; }

        &>span {
          &:first-of-type {
            display: block; }
          &:last-of-type {
            display: none; } }

        &.processed {
          opacity: 0.6; }

        &:hover {
          .schedule__table-item-btn-icon {
            background-color: $color-red;

            &::before,
            &::after {
              background-color: $white; } } }

        &.subscribed {
          &>span {
            &:first-of-type {
              display: none; }

            &:last-of-type {
              display: block; } }

          .schedule__table-item-btn-icon {
            border-color: $grey-c0;

            &::before,
            &::after {
              background-color: $grey-88; }

            &::before {
              width: 0.545em;
              transform: translateX(-0.25em) rotate(45deg); }

            &::after {
              transform: translateX(0.25em) rotate(135deg); } }

          &:hover {
            .schedule__table-item-btn-icon {
              background-color: $white;

              &::before {
                width: 0.909em;
                transform: translateX(0) rotate(45deg); }

              &::after {
                transform: translateX(0) rotate(135deg); } } } }

        &-icon {
          position: relative;
          width: 3.09em;
          height: 3.09em;
          margin-left: 0.9875em;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $red;
          border-radius: 0.3636em;
          transition: all 0.2s ease-out;

          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 0.909em;
            height: 0.182em;
            background-color: $red;
            border-radius: 1em;
            transition: all 0.2s ease-out; }

          &::after {
            transform: rotate(90deg); } }

        &--look {
          .schedule__table-item-btn-icon {
            background-color: $white;
            border-color: $grey-c0;

            &::before,
            &::after {
              display: none; }

            svg,
            path {
              fill: $red; } }

          &:hover {
            .schedule__table-item-btn-icon {
              background-color: $red;
              border-color: $red;

              svg,
              path {
                fill: $white; } } } } }

      &-company {
        @include fs11;
        margin-top: 1.0909em;
        color: $grey-88; }

      &-netw {
        display: grid;
        row-gap: 1.25rem;
        grid-template-rows: repeat(2, max-content);

        &-content {
          display: grid;
          row-gap: 0.5rem; }

        &-title {
          @include reset;
          font-weight: 800; }

        &-desc {
          @include reset;
          color: $dark-grey;
          font-size: 0.8125rem; }

        &-btn {
          .btn {
            width: 100%; } } } } }

  &__expert {
    display: grid;
    grid-template-columns: 3.5rem 1fr;
    grid-gap: 0.25rem 1rem;

    &-photo {
      width: 3.5em;
      height: 3.5em;
      grid-row: 1 / span 2;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      overflow: hidden; }

    &-name {
      align-self: start;
      font-weight: 800; }

    &-workplace {
      font-size: 0.6875em;
      line-height: 1.35; } } }

@for $i from 1 through 48 {
  .schedule__table[data-period-count="#{$i}"] {
    .schedule__table-item-wrapper {
      @include media-desktop {
        grid-template-rows: repeat($i, 8.5em); } }

    .schedule__table-bg-wrapper {
      @include media-desktop {
        grid-template-rows: repeat($i, 8.5em); } }

    .schedule__table-day {
      @include media-desktop {
        grid-template-rows: 2.625em repeat($i, 8.5em); } }

    &[data-period-length="60"] {
      .schedule__table-item-wrapper {
        @include media-desktop {
          grid-template-rows: repeat($i, 17em); } }

      .schedule__table-bg-wrapper {
        @include media-desktop {
          grid-template-rows: repeat($i, 17em); } }

      .schedule__table-day {
        @include media-desktop {
          grid-template-rows: 2.625em repeat($i, 17em); } } } } }

@for $i from 1 through 48 {
  .schedule__table-item[data-event-duration="#{$i}"] {
    grid-row-end: span $i; } }

@for $i from 1 through 48 {
  .schedule__table-item[data-start-time="#{$i}"] {
    grid-row-start: $i; } }

// ! Кастомное брендирование
//// * date_134 - Греция
//// * date_194 - Беларусь

.schedule__list-day {
  &#date_134 {
    .schedule__item {
      color: $white;

      &-header {
        background-color: #20ADE4;

        @include media-tablet {
          background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-greece.svg");
          background-repeat: no-repeat;
          background-position: right 1em center; } }

      &-time {
        &::before {
          background-color: $white; } } } }

  &#date_194 {
    .schedule__item {
      color: $white;

      &-header {
        background-color: #234B97;

        @include media-tablet {
          background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-bellarus.svg");
          background-repeat: no-repeat;
          background-position: right center;
          background-size: contain; } }

      &-time {
        &::before {
          background-color: $white; } } } } }

.schedule__table-day {
  &#date_134 {
    .schedule__table-header {
      color: $white;
      background-color: #20ADE4;
      background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-greece.svg");
      background-repeat: no-repeat;
      background-size: auto 12px;
      background-position: right 11px center; } }

  &#date_194 {
    .schedule__table-header {
      color: $white;
      background-color: #234B97;
      background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-table-bellarus.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right center; } } }

.schedule__list-day-date-name {
  display: none;

  @include media-tablet-h {
    display: inline; } }

.schedule__list-day-week {
  text-transform: capitalize; }

.schedule__list-day {
  .schedule__list {
    &-day-header--krd,
    &-day-header--ak,
    &-day-header--kp,
    &-day-header--kam,
    &-day-header--ao,
    &-day-header--ntm,
    &-day-header--pt,
    &-day-header--vt,
    &-day-header--ra,
    &-day-header--rs,
    &-day-header--inv,
    &-day-header--lux,
    &-day-header--iro,
    &-day-header--kav,
    &-day-header--networking-day,
    &-day-header--sam,
    &-day-header--anex,
    &-day-header--sochi,
    &-day-header--event,
    &-day-header--ekb,
    &-day-header--khmao,
    &-day-header--tyumen,
    &-day-header--kr,
    &-day-header--tat {
      position: relative;
      color: $white;

      hr {
        background: $white; } }

    &-day-header--krd {
      background: $grey-e5;
      color: $color-black;

      hr {
        background: $color-black; }

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-8.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--ak {
      background: linear-gradient(90deg, #96C32B 0.96%, #45A63B 98.09%);

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 4.596875em;
          height: 3.5em;
          background: $white url(@public/images/schedule/schedule-ak.png) no-repeat center;
          right: 0; }

        &::before {
          position: absolute;
          content: '';
          width: 2em;
          height: 3.5em;
          background: $white;
          right: 4em;
          border-bottom-left-radius: 5em;
          border-top-left-radius: 5em; } } }

    &-day-header--kp {
      background: #658FF9;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-1.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--kam {
      background: #2B4B87;

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 9.0625em;
          height: 2.5em;
          background: $white url(@public/images/schedule/schedule-kam.png) no-repeat center;
          right: 2em; }

        &::before {
          position: absolute;
          content: '';
          width: 17em;
          height: 3.5em;
          background: $white;
          right: -3em;
          transform: skew(-45deg); } } }

    &-day-header--ao {
      background: #76BBE4;

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 9.4375em;
          height: 3em;
          background: $white url(@public/images/schedule/schedule-ao.png) no-repeat center;
          right: 2em; }

        &::before {
          position: absolute;
          content: '';
          width: 17em;
          height: 3.5em;
          background: $white;
          right: -3em;
          transform: skew(-45deg); } } }

    &-day-header--ntm {
      background: #2B446A;

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 12.3125em;
          height: 3.5em;
          background: url(@public/images/schedule/schedule-ntm.png) no-repeat center;
          right: 0; } } }

    &-day-header--pt {
      background: #E61F24;

      .schedule__list-day-date-wrapper {
        z-index: 1; }

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 36.1875em;
          height: 3.5em;
          background: url(@public/images/schedule/schedule-pt.png) no-repeat center;
          right: 0; } } }

    &-day-header--vt {
      background: #8CC431;

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 15.75em;
          height: 3.5em;
          background: url(@public/images/schedule/schedule-vt.png) no-repeat center;
          right: 0; } } }

    &-day-header--ra {
      background: #B0C6FF;
      color: $color-black;

      hr {
        background: $color-black; }

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-6.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--rs {
      background: linear-gradient(90deg, #006666 0.49%, #009595 100%);

      @include media-desktop {
        background: linear-gradient(90deg, #006666 0.49%, #009595 97%, $white 97%, $white 100%);

        &::after {
          position: absolute;
          content: '';
          right: 0;
          width: 12.3125em;
          height: 3.5em;
          background: $white url(@public/images/schedule/schedule-rs.png) no-repeat left center; }

        &::before {
          position: absolute;
          content: '';
          right: 12.3125em;
          width: 7.5625em;
          height: 3.5em;
          background: $white;
          border-top-left-radius: 5em; } } }

    &-day-header--inv {
      background: #232226;

      @include media-desktop {
        &::after {
          position: absolute;
          content: '';
          width: 19.6875em;
          height: 3.5em;
          background: url(@public/images/schedule/schedule-inv.png) no-repeat center;
          right: 0; } } }

    &-day-header--lux {
      background: #8447C3;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-7.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--iro {
      background: linear-gradient(90deg, #007248 0, #04B172 100%);

      @include media-desktop {
        background: linear-gradient(90deg, #007248 0, #04B172 97%, $white 97%, $white 100%);

        &::after {
          position: absolute;
          content: '';
          right: 0;
          width: 14.25em;
          height: 3.5em;
          background: $white url(@public/images/schedule/schedule-iro.png) no-repeat left center; }

        &::before {
          position: absolute;
          content: '';
          right: 14.25em;
          width: 5.625em;
          height: 3.5em;
          background: $white;
          border-top-left-radius: 5em; } } }

    &-day-header--kav {
      background: #262161;

      @include media-desktop {
        background: linear-gradient(90deg, #262161 0, #262161 97%, $white 97%, $white 100%);

        &::after {
          position: absolute;
          content: '';
          right: 0;
          width: 15.25em;
          height: 3.5em;
          background: $white url(@public/images/schedule/schedule-kav.png) no-repeat left center; }

        &::before {
          position: absolute;
          content: '';
          right: 15.25em;
          width: 4.625em;
          height: 3.5em;
          background: $white;
          border-top-left-radius: 5em; } } }

    &-day-header--networking-day {
      background: $color-yellow-new;
      color: $color-black;

      hr {
        background: $color-black; }

      @include media-tablet {
        background: linear-gradient(90deg, $color-yellow-new 0, $color-yellow-new 97%, $white 97%, $white 100%);

        &::after {
          position: absolute;
          content: '';
          right: 0;
          width: 15.375em;
          height: 3.5em;
          background: $white url(@public/images/schedule/networking-day.svg) no-repeat center center;
          border-top-left-radius: 3.125em;

          @include media-desktop {
            width: 17.875em; } } } }

    &-day-header--sam {
      background: #17A150;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-5.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--anex {
      background: linear-gradient(90deg, #ED1C24 0, #2E3192 100%);

      @include media-desktop {
        background: linear-gradient(90deg, #ED1C24 0, #2E3192 97%, $white 97%, $white 100%);

        &::after {
          position: absolute;
          content: '';
          width: 13.4375em;
          height: 3.5em;
          background: $white url(@public/images/schedule/schedule-anex.svg) no-repeat left center;
          right: 0; }

        &::before {
          position: absolute;
          content: '';
          right: 13.4375em;
          width: 4.9375em;
          height: 3.5em;
          background: $white;
          border-radius: 2.8125em 0 0 2.8125em; } } }

    &-day-header--sochi {
      background: #8261c9;

      @include media-desktop {
        background: linear-gradient(90deg, #8261c9 0, #8261c9 97%, $white 97%, $white 100%);

        &::after {
          position: absolute;
          content: '';
          right: 0;
          width: 18.375em;
          height: 3.5em;
          background: $white url(@public/images/schedule/schedule-sochi.png) no-repeat center center;
          background-size: auto 2.1875em;
          border-radius: 2.8125em 0 0 2.8125em; } } }

    &-day-header--ekb {
      background: #55BD4E;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-2.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--tyumen {
      background: $color-black;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18.75em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-3.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-day-header--khmao {
      background: #E61F24;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-4.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; }

        @include media-desktop-1440 {
          width: 18.75em; } } }

    &-day-header--kr {
      background: #C3FF00;
      color: $color-black;

      hr {
        background: $color-black; }

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-9.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; }

        @include media-desktop-1440 {
          width: 18.75em; } } }

    &-day-header--tat {
      background: #FFAF1A;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        display: none;
        width: 18em;
        height: 3.5em;
        background: url(@public/images/schedule/figure-10.png) 50% / contain no-repeat;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; }

        @include media-desktop-1440 {
          width: 18.75em; } } } } }

.schedule__table-day {
  .schedule__list-day-header--kr,
  .schedule__list-day-header--ak,
  .schedule__list-day-header--kp,
  .schedule__list-day-header--kam,
  .schedule__list-day-header--ao,
  .schedule__list-day-header--ntm,
  .schedule__list-day-header--pt,
  .schedule__list-day-header--vt,
  .schedule__list-day-header--ra,
  .schedule__list-day-header--rs {
    position: relative;
    color: $white;
    font-weight: 400;

    hr {
      background: $white; } }

  .schedule__list-day-header--kr {
    background: #6178EF;

    &::after {
      position: absolute;
      content: '';
      width: 4.6em;
      height: 3em;
      background: url(@public/images/schedule/schedule-kr-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--ak {
    background: #96C32B;

    &::after {
      position: absolute;
      content: '';
      width: 4.6em;
      height: 3em;
      background: url(@public/images/schedule/schedule-ak-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--kp {
    background: #E61F24;

    &::after {
      position: absolute;
      content: '';
      width: 4.6em;
      height: 3em;
      background: url(@public/images/schedule/schedule-kp-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--kam {
    background: #2B4B87;

    &::after {
      position: absolute;
      content: '';
      width: 1em;
      height: 1.75em;
      background: url(@public/images/schedule/schedule-kam-mobile.png) no-repeat center;
      right: 1em;
      top: 0.5em;
      background-size: cover; }

    &::before {
      position: absolute;
      content: '';
      width: 5em;
      height: 3.5em;
      background: $white;
      right: -2em;
      transform: skew(-25deg); } }

  .schedule__list-day-header--ao {
    background: #76BBE4;

    &::after {
      position: absolute;
      content: '';
      width: 4em;
      height: 2.625em;
      background: url(@public/images/schedule/schedule-ao-mobile.png) no-repeat center;
      right: 0.8em;
      top: 0.1em;
      background-size: cover; }

    &::before {
      position: absolute;
      content: '';
      width: 6em;
      height: 3.5em;
      background: $white;
      right: -1em;
      transform: skew(-25deg); } }

  .schedule__list-day-header--ntm {
    background: #2B446A;

    &::after {
      position: absolute;
      content: '';
      width: 6.1428em;
      height: 3em;
      background: url(@public/images/schedule/schedule-ntm-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--pt {
    background: #E61F24;

    &::after {
      position: absolute;
      content: '';
      width: 4.9285em;
      height: 3em;
      background: url(@public/images/schedule/schedule-pt-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--vt {
    background: #8CC431;

    &::after {
      position: absolute;
      content: '';
      width: 6.1428em;
      height: 3em;
      background: url(@public/images/schedule/schedule-vt-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--ra {
    background: #7ABCD8;

    &::after {
      position: absolute;
      content: '';
      width: 5em;
      height: 3em;
      background: url(@public/images/schedule/schedule-ra-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } }

  .schedule__list-day-header--rs {
    background: #006F46;

    &::after {
      position: absolute;
      content: '';
      width: 5em;
      height: 3em;
      background: url(@public/images/schedule/schedule-rs-table.png) no-repeat center;
      right: 0;
      top: 0;
      background-size: cover; } } }
