.cabinet {
  display: grid;
  grid-template-columns: 15.3125em 1fr;
  grid-template-rows: 100%;
  min-width: 1280px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  color: $dark-grey;

  &__netw-profile {
    display: grid;
    row-gap: 2.5rem;
    max-width: 79.625rem;
    margin: 0 auto; }

  &__side {
    background-color: $white; }

  &__main {
    padding: 2.5em;
    background: $grey-f5; }

  &__block {
    padding: 2em;
    background-color: $white;
    border-radius: 0.75rem;

    @include media-desktop-1600 {
      padding: 2.5em; } }

  &__layout {
    display: grid;
    row-gap: 2.5em;
    margin: 0 auto;
    color: $dark-grey;

    &-body {
      display: grid;
      grid-template-columns: 1fr 21.125em;
      column-gap: 2em;
      align-items: start;

      @include media-desktop-1600 {
        grid-template-columns: 1fr 28em; }

      @include media-desktop-1920 {
        grid-template-columns: 1fr 35.375em; } }

    &-col {
      display: flex;
      flex-direction: column;
      row-gap: 1.25em;

      &--gap0 {
        row-gap: 0; } } }

  &__title {
    @include reset;
    @include h36;

    &--networking {
      @include fs16;
      display: grid;
      grid-template-columns: 1fr 27.375rem;

      h1 {
        @include reset;
        @include h36; } }

    &-contacts {
      @include fs14;
      display: grid;
      row-gap: 0.25rem;
      font-weight: 400;

      &-content {
        font-weight: 700; }

      &-support {
        display: grid;
        row-gap: 0.25rem;
        color: $grey-c0;

        a {
          color: $blue; }

        p {
          @include reset; } } } } }
