.stands {
  padding-top: 5em;
  background-color: $color-black;

  @include media-tablet {
    padding-top: 5em; }

  &__show-more-button {
    @include fs16;
    color: $color-black;
    font-weight: 700;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #C0C0C0;
    border-radius: 20px;
    background-color: #FFFFFF;

    @include media-tablet {
      display: flex; } }

  .cont {
    width: 100%;

    @include media-desktop-1600 {
      width: 100rem; } }

  &__wrapper-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    gap: 30px;

    @include media-desktop-1440 {
      flex-direction: row; } }

  &__title {
    font-weight: 800; }

  &__subtitle {
    font-size: 0.875rem;

    @include media-tablet {
      font-size: 1.125rem; }

    @include media-desktop-1440 {
      max-width: 623px; }

    @include media-desktop-1600 {
      max-width: 704px; } }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.25em; }

  &__filter {
    padding-bottom: 5em;

    &-list {
      @extend .schedule__filter-list;
      border-radius: 41px;
      background-color: #E5E5E5;
      padding: 8px;
      gap: 8px; }

    &-item {}

    &-btn {
      display: flex;
      align-items: center;
      @include fs16;
      min-width: auto;
      height: 36px;
      padding: 7.5px 12px;
      border-radius: 2.875rem;
      border: 1px solid $grey-e5;
      color: $color-black;
      background-color: transparent;

      @include media-tablet-h {
        @include fs20;
        padding: 9px 12px; }

      &:hover {
        border-color: #185ADB; }

      &--active {
        border-color: #185ADB;
        color: rgb(24, 90, 219);
        background: $white; } }

    &-group {
      margin-top: 2.5em;

      &--direction {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }

    &-select {
      position: relative;
      width: 100%;
      border-radius: 2rem;
      background-color: $white;

      @include media-tablet {
        width: auto; }

      .netw-filter__country-reset {
        padding: 0 1.1rem; }

      &::after {
        content: "";
        position: absolute;
        right: 2em;
        top: 50%;
        width: 11px;
        height: 18px;
        transform: translateY(-50%) rotate(90deg);
        background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-slider-arrow.svg");
        background-repeat: no-repeat;
        z-index: 53; }

      input {
        @include p18;
        position: relative;
        display: block;
        width: 100%;
        padding: 0.666em 1.111em;
        border: none;
        border-radius: 2rem;
        background-color: transparent;
        appearance: none;
        z-index: 5;

        @include media-tablet {
          width: 22.5em;
          display: inline; } } }

    &-show-text {
      @include reset;
      @include p18;
      margin-top: 1rem;
      color: $white;
      border: none;

      @include media-tablet {
        margin-left: 1.777em;
        margin-top: 0; }

      button {
        color: #FFEE00;
        font-size: 1em;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none; } } } }

  &__general {
    padding-top: 2.5em;
    padding-bottom: 5em;
    background-color: $white;

    @include media-tablet {
      padding-top: 5em; } }

  &__not-general {
    position: relative;
    padding-bottom: 5em;
    z-index: 1;

    @include media-desktop {
      overflow: hidden; }

    .cont {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 2em;

      @include media-tablet {
        row-gap: 4em; } } }

  &__group {
    padding-top: 40px;

    @include media-desktop-1440 {
      row-gap: 80px; }

    .cont {
      display: grid;
      display: flex;
      flex-direction: column; }

    &--hidden {
      display: none; }

    &-title {
      @include h24;
      position: relative;
      font-weight: 800;
      color: $white;

      @include media-tablet {
        @include h36; }

      span {
        color: $color-yellow-new !important;
        text-transform: uppercase; } }

    &-list {
      @include media-tablet {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        column-gap: 1.875em;
        grid-row-gap: 1.875em; }

      @include media-tablet-h {
        row-gap: 2em;
        grid-template-columns: repeat(6, 0.5fr); }

      @include media-desktop {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2em; } }

    &--general {
      .stands__group-title {
        color: $white; } }

    &--partners {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 2.5em;

      span {
        color: inherit; } }

    &--participants {
      display: flex;
      flex-direction: column;
      row-gap: 2.5em;

      span {
        color: inherit; } } }

  &__slider {
    &:nth-of-type(n + 2) {
      margin-top: 2.5em;

      @include media-tablet {
        margin-top: 2em; } } }

  .swiper-pagination {
    position: relative;
    bottom: 0 !important;
    margin-top: 1.5em;

    @include media-tablet {
      display: none; } }

  .swiper-container {
    width: 100%; }

  .swiper-pagination-bullet {
    width: 0.625em;
    height: 0.625em;
    background-color: $grey-89;
    opacity: 1;

    &-active {
      background-color: $white; } }

  &__group--general {
    .swiper-pagination-bullet {
      background-color: $white;

      &-active {
        background-color: $color-yellow-new; } } } }
