.plan {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2.5em 1.25em;
  background: $grey-f5;
  flex-grow: 1;

  @include media-tablet {
    padding: 4em 0; }

  @include media-desktop {
    min-height: 54.375em;
    align-items: center; }

  &__wrapper {
    display: flex;
    flex-direction: column;
    background: $white;
    row-gap: 1.25em;
    max-width: 768px;
    padding: 20px;
    border-radius: 24px;

    @include media-tablet {
      padding: 80px;
      row-gap: 2em; } }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 14px; }

  &__title {
    @include reset;
    @include h24;
    font-weight: 700;

    @include media-tablet {
      font-size: 2.25rem; } }

  &__description {
    display: flex;
    flex-direction: column;
    row-gap: 0.625em;

    @include media-tablet {
      row-gap: 1.25em; }

    p {
      @include reset;
      @include fs14;

      @include media-tablet {
        @include fs16; } } }

  &__subtitle {
    @include reset;
    @include fs16;
    font-weight: normal;

    @include media-tablet {
      @include fs18; } }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 1.25em;

    @include media-tablet {
      row-gap: 2em; }

    &-group {
      display: flex;
      flex-direction: column;
      row-gap: 0.9375em; } }

  &__radio {
    position: relative;
    border-radius: 1.25em;
    border: 1px solid $blue;
    transition: border ease 0.3s;

    input:checked + .plan__radio-label {
      .plan__radio-header {
        &::before {
          background-image: url("@public/images/icon-radio-active.svg"); } } }

    &-label {
      display: flex;
      flex-direction: column;
      row-gap: 1.25em;
      padding: 1.25em;
      cursor: pointer;

      @include media-tablet {
        padding: 2.5em; } }

    &-header {
      display: flex;
      align-items: center;
      column-gap: 1.25em;

      &::before {
        content: '';
        flex: 0 0 1.5625em;
        width: 1.5625em;
        height: 1.5625em;
        background: url("@public/images/icon-radio.svg") no-repeat;
        transition: background ease 0.3s; } }

    &-title {
      @include fs16;
      font-weight: 700;

      @include media-tablet {
        font-size: 1.125em; } }

    &-desc {
      @include fs14;

      @include media-tablet {
        @include p16; } }

    &--active {
      border-color: $blue; } } }
