.radio {
  display: flex;
  background-color: $grey-f5;
  border-radius: 12px;
  user-select: none;

  @include max-w(500px) {
    flex-direction: column; }

  label {
    @include fs16;
    height: 48px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 12px;
    border: 1px solid $grey-f5;
    cursor: pointer;
    color: $grey-c0; }

  input:checked + label {
    background-color: $white;
    border: 1px solid $blue;
    color: $color-black; } }
