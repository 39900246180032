.steps {
  padding: 3.75em 0 5em;
  background: $color-black;
  color: $white;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;

  @include media-tablet {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include media-tablet-h {
    padding-top: 100px;
    padding-bottom: 100px; }

  @include media-desktop-1440 {
    padding-top: 120px;
    padding-bottom: 120px; }

  @include media-desktop-1600 {
    padding-top: 140px;
    padding-bottom: 140px; }

  &__bg {
    content: '';
    position: absolute;
    top: -691.2px;
    right: -396vw;
    width: 1397px;
    height: 1397px;
    background: url(@public/images/prizes/bg.svg) no-repeat;
    background-size: cover;
    z-index: 0;

    @include media-tablet {
      right: -138vw; }

    @include media-tablet-h {
      right: -64vw; }

    @include media-desktop-1440 {
      right: -44vw; }

    @include media-desktop-1920 {
      right: -32vw; } }

  &__cont {
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 1;
    position: relative;

    @include media-tablet {
      row-gap: 40px; } }

  &__title {
    z-index: 1;
    @include reset;
    @include h36;

    @include media-tablet {
      @include h48;
      max-width: 34rem; } }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    position: relative;
    z-index: 1; }

  &__item {
    display: flex;
    align-items: center;
    column-gap: 0.875em;

    @include media-tablet {
      column-gap: 1.25em; }

    @include media-tablet-h {
      width: calc(50% - 1.5625em); }

    @include media-desktop {
      width: calc(50% - 3.125em); }

    @include media-desktop-1440 {
      width: 35em; }

    &:first-child,
    &:last-child {
      .steps__item-desc {
        @include media-desktop-1440 {
          max-width: 21.25rem; } } }

    &:first-child {
      @include media-tablet-h {
        order: 1; } }

    &:nth-child(2) {
      @include media-tablet-h {
        order: 3; } }

    &:nth-child(3) {
      @include media-tablet-h {
        order: 2; } }

    &:last-child {
      @include media-tablet-h {
        order: 4; } }

    &-num {
      flex: 0 0 3.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.875rem;
      height: 3.875rem;
      border-radius: 1rem;
      border: 3px solid $color-yellow-new;
      font-size: 2.875em;
      font-weight: 800;
      line-height: 1;
      color: $color-yellow-new;

      @include media-tablet {
        flex-basis: 6.25rem;
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 1.5rem;
        border-width: 4px;
        font-size: 4.375em; }

      @include media-desktop {
        flex-basis: 9.625rem;
        width: 9.625rem;
        height: 9.625rem;
        font-size: 7.3125em; } }

    &-desc {
      @include fs14;
      font-weight: 600;

      @include media-tablet {
        font-size: 1.25em; }

      @include media-desktop-1440 {
        @include fs24; }

      br {
        display: none;

        @include media-tablet-h {
          display: block; } }

      a {
        color: $color-yellow-new;
        font-size: 0.75rem;
        text-decoration-line: underline;
        outline: none;

        @include media-tablet {
          font-size: 1.25rem; }

        @include media-desktop-1440 {
          font-size: 1.5rem; }

        &:hover {
          text-decoration: none; } } } }

  &__note {
    @include fs18;
    font-weight: 600;

    @include media-tablet {
      @include fs24; }

    @include media-tablet-h {
      font-size: 1.75em; }

    @include media-desktop {
      font-size: 1.875em; } } }
