.react-tel-input.phone-input {

  width: 100%;

  .form-control {
    @include p14;

    width: 100%;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #e5e5e5; }

  .flag-dropdown {
    border-radius: 12px 0 0 12px;
    background-color: transparent;
    border: 1px solid #e5e5e5;

    &.open {
      border-radius: 12px 0 0 12px;

      .selected-flag {
        background-color: transparent; } }

    .selected-flag {
      background-color: transparent;

      &:hover {
        background-color: transparent; } } } }
